import _descriptors from "../internals/descriptors";
import _functionName from "../internals/function-name";
import _functionUncurryThis from "../internals/function-uncurry-this";
import _objectDefineProperty from "../internals/object-define-property";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var DESCRIPTORS = _descriptors;
var FUNCTION_NAME_EXISTS = _functionName.EXISTS;
var uncurryThis = _functionUncurryThis;
var defineProperty = _objectDefineProperty.f;
var FunctionPrototype = Function.prototype;
var functionToString = uncurryThis(FunctionPrototype.toString);
var nameRE = /function\b(?:\s|\/\*[\S\s]*?\*\/|\/\/[^\n\r]*[\n\r]+)*([^\s(/]*)/;
var regExpExec = uncurryThis(nameRE.exec);
var NAME = "name"; // Function instances `.name` property
// https://tc39.es/ecma262/#sec-function-instances-name

if (DESCRIPTORS && !FUNCTION_NAME_EXISTS) {
  defineProperty(FunctionPrototype, NAME, {
    configurable: true,
    get: function () {
      try {
        return regExpExec(nameRE, functionToString(this || _global))[1];
      } catch (error) {
        return "";
      }
    }
  });
}

export default {};